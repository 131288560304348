body {
  --black: #111111;
  --white: #fdfdfd;

  --background: var(--white);
  --accent: var(--black);

  margin: 0;
  /* padding: 0 1rem; */
  background-color: var(--background);
  color: var(--accent);
  padding-bottom: 100px;

}

* {
  font-family: sofia-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3 {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  margin: 10px 0 40px;
}

h2 {
  font-weight: 400;
  margin: 10px 20px 10px;
}

p {
  /* margin: 0 0 30px 0; */
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 1px,
    var(--background) 1px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
  font-size: 14px;
  line-height: 14px;
}

footer::before {
  display: block;
  content: "";
  position: sticky;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--accent);
  opacity: 0.2;
}


footer a {
  text-decoration: none;
  color: var(--accent);
}

article {
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--purple);
  transform-origin: 0%;
}
