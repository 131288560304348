/* About me */
body {
 
  padding-top: 2em;

  justify-content: center;
}
.aboutPage{
 
  max-width: 68.75rem;
  margin: auto;
  text-align: left;
  margin-top: 2.5rem;

  
}

h2{
  /* margin-bottom: 1rem; */
  margin: 10px 20px 10px;
}
  
  .bio {
    width: 25rem;
    padding: 0.625rem;
    border-radius: 6px;
    box-shadow: 0px 2px 15px 2px var(--primary-shadow);
  }
  
  .bio h1 {
    margin-bottom: var(--bottom-margin);
  }
  
  .bio p {
    line-height: var(--line-height);
    padding: 0.1rem 0;
  }
  
  .aboutIntro p {
    color: #333;
    font-family: monospace;
    white-space: normal;
    overflow: hidden; 
  
    margin: 0 auto; 
    letter-spacing: .15em;
    
  }
  .aboutIntro p span{
    border-right: .15em solid orange; 

      animation: 
      typing 2.5s steps(20, end),
      blink-caret .5s step-end infinite;
    
  }
  
  /* The typing effect */
  @keyframes typing {
    from { width: 0 }
    to { width: 80% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: orange }
  }

  /* More about me */
  .moreAbout {
  
    background-color: var(--bg-color);
    /* padding: 1rem 6rem; */
    margin-bottom: 2rem;
  }
  .moreAbout h2{
    margin:0px;
  }
 
  .moreAbout p {
    line-height: var(--line-height);
    padding: 0.4rem;
  }
  .about {
    text-align: center;
  }
  
  .aboutIntro {
    margin-bottom: 20px; 
   
  }
  .aboutIntro p{
    font-family: 'Roboto Mono', monospace;
    font-size: 28px;
    font-weight: bold; 
  }
  
  .imgageStye {
    display: block;
    margin: 0 auto;
    width: 40%; 
    height: 40%; 
    border-radius: 50%;
    overflow: hidden; 
  }
  @media screen and (max-width: 420px) {
    .aboutIntro{
      height: 80px; 
      overflow-y: auto; 
      margin-bottom:0px ;
    }
    .aboutIntro p{
      font-family: 'Roboto Mono', monospace;
      font-size: 20px; 
      font-weight: bold;
      white-space: pre-wrap;
      overflow: hidden; 
    }
    
    .imgageStye {
      display: block;
      margin: 0 auto;
      width: 80%; 
      height: 70%; 
      border-radius: 50%; 
      overflow: hidden; 
    }
  .moreAbout {
    background-color: var(--bg-color);
    padding: 2rem;
  }
  }