footer {
    background-color: var(--bg-color);
    padding: 1.25rem;
    text-align: center;
    margin: 2rem 0 0;
    display: inline-block;
    position: fixed;
    bottom: 0;
  padding: 2px;
   
  
   
  }
  .copy {
    display: flex;
    align-items: center;
    justify-content: center; 
    font-size: 16px; 
    margin-top: 10px; 
   
  }
  
  .copy img {
    margin-left: 5px; 
  }