.socials {
    display: flex;
    flex-direction: column;
    position: fixed;
    right: 1%;
    bottom: 50%;
  }
  
  .socicon {
    width: 2rem;
    height: 2rem;
  }
  
  /* @media (max-width: 767px) {
    .socials{
      display: none;
    }
  } */

    /* Customize the styles based on your design */
    @media screen and (max-width: 420px) {
      /* .socials {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

margin-bottom: 2%;
        height: 10vh; 
        position: fixed;


      
      } */
      .socials {

        position: absolute;
        right: 1%;
        top: calc(10% + 300px);
        display: flex;
        flex-direction: row;
        margin-right: 40%;
        /* margin-top: 70px; */
      }

      
      /* .socicon { */
        /* margin-right: 10px;  */
        /* position: fixed;
       
      } */
      
 
        }