.projects {
    /* background-color: var(--bg-color); */
    max-width: 68.75rem;
    margin: auto;
    text-align: left;
    /* margin-top: 2.5rem; */
    height: 100vh;
   
     
  }
  
   .projectPic {
    width: 25rem;
    height: 20rem;
    border-radius: 2%;
  }
  
  .projectsContainer{
   
    gap: 20px; 
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4rem;
    /* display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    background-color: pink; */
    
  }
  .coffee{
    width: 25rem;
    height: 20rem;
    border-radius: 2%;
  }
 
  .projectTitle {
    /* text-align: center;
    margin: 1rem; */
    font-family: 'Poppins', sans-serif;

  }
  .projectDetails{
    /* font-family: 'Spectral', serif;
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 2rem; */
     font-family: sofia-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 2rem;
  }

  .projectsContainer p {
    padding: 0.4rem;
    
  }
  .projectCard{
    box-shadow: 0 3px 10px var(--primary-shadow);
    display: flex;
    width: 100%; /* Ensure the card takes the full width of its container */
    box-sizing: border-box;

    /* margin: 20px; */
    /* display: block; */
    margin-bottom: 5rem;
  }
  .projectHeader{
    margin-bottom: 1rem;
  }
  .projectImage {
    flex: 1; /* Make projectImage take up available space */
  }
  
  .textCard {
    flex: 1; /* Make textCard take up available space */
    padding: 20px 20px; /* Add padding for better spacing */
    box-sizing: border-box;
   
  }
 


/* Styles for small screens (e.g., mobile devices) */
@media (max-width: 767px) {
  .projects {
    /* background-color: var(--bg-color); */
    height: 100vh;
     
  }
  .projectsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 5rem;
  }
  .coffee{
    width: 100%;

  }
  .projectCard {
    width: 100%;
  
    display: block;
    margin-bottom: 5rem;
  }
  
  .projectImage {
    text-align: center; /* Center the image */
  }
  
  .projectPic {
    max-width: 100%;
    height: auto;
  }
  
  .textCard {
    text-align: center;
 
  }
  
  .buttonContainer {
    text-align: center;
    margin-top: 10px; /* Adjust the margin based on your design */
  
  }
  
  
}
