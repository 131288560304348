nav {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 3.5rem;
    background-color: var(--bg-color);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  }
  nav h1 {
    color: var(--primary-color);
  }
  
  nav a {
    color: var(--primary-color);
    transition: var(--transition);
  }
  nav a:hover {
    color: var(--secondary-color);
    border-bottom: 2px solid var(--secondary-color);
  }
  
  nav ul {
    display: flex;
    gap: 1.9rem;
  }
  
  nav ul li {
    font-weight: var(--bold-font);
  }
  .burgerMenu {
    color: var(--primary-color);
    font-size: 2rem;
    border: 0;
    background-color: transparent;
    cursor: pointer;
    display: none;
  }
  /* .show {
    transform: translateX(0);
  } */
  @media screen and (max-width: 420px) {
    nav {
      padding: 1.5rem 1rem;
    }
    .navigation {
      position: fixed;
      background-color: var(--bg-color);
      flex-direction: column;
      top: 86px;
      left: 10%;
      width: 80%;
      text-align: center;
      transform: translateX(120%);
      transition: transform 0.5s ease-in;
    }
  
    .navlink {
      margin: 8px;
    }
  
    .burgerMenu {
      display: block;
    }
    .show {
      transform: translateX(0);
    }
  }