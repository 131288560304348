@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,900;1,700&display=swap");

/* Variables */
:root {
  --font-family: "Roboto", sans-serf;
  --normal-font: 400;
  --bold-font: 700;
  --bolder-font: 900;
  --bg-color: #fcfcfc;
  --primary-color: #4756df;
  --secondary-color: #ff7235;
  --primary-shadow: #8b8eaf;
  --secondary-shadow: #a17a69;
  --bottom-margin: 0.5rem;
  --bottom-margin-2: 1rem;
  --line-height: 1.7rem;
  --transition: 0.3s;
 
}
/* Variables end */
#root{
  padding: 0rem 1rem;
}

html {
  scroll-behavior: smooth;
}
/* CSS Resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  ul {
    list-style-type: none;
  }
  
  a {
    text-decoration: none;
    color: var(--primary-color);
  }
  
  a:hover {
    color: var(--secondary-color);
  }
  
  body {
    font-family: var(--font-family);
  }