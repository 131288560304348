.skills {
    max-width: 68.75rem;
    margin: auto;
    text-align: left;
    /* margin-top: 2.5rem; */
    
  }
  h1{
    font-family: 'Spectral', serif;
    font-size: 2.2rem;
    font-weight: 900;
  }
  
  .skillHeader {
    margin-bottom: 1rem;
  }
  .item{
    vertical-align: top;
    display: inline-block;
    text-align: center;

  }
  /* .item img {
    width: 10rem;
  } */
  /* .skillsWapper{
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 120px;
  } */
.skillsWrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
}
  .caption{
    display: block;
    font-family: 'Spectral', serif;
    font-weight: bolder;
  }
  /* .icon {
    width: 11.875rem;
    height: 11.25rem;
  } */
  .iconCard {
    /* width: 11.875rem;
    height: 11.25rem; */
    height: 7rem;

   
    background-color: #fff;
    border-radius: 11px;
    /* box-shadow: 0 3px 10px var(--secondary-shadow); */
    /* padding: 20px;
    margin: 10px; */
    padding: 19px;
    margin: 20px;
  }

  @media (max-width: 420px) {
    .mainSkillsClass{

      margin-bottom: 1rem;
      /* padding: 2px; */
    }
    .skills{

     
      display: inline-block;
    }
    .skillHeader{
     
      margin-bottom: 0rem;
    }
    
    h1{
      margin: 20px;
    }
    
    .item {
      text-align: center;

      margin-bottom: 20px; /* Adjust margin based on your design */
    }
    
    .iconCard {
      max-width: 100%;

    }
    
    .caption {
      display: block;
      margin-top: 10px; /* Adjust margin based on your design */
    }
    
  }
